import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import {
  DataGrid,
  GridPagination,
  GridToolbar,
  gridClasses,
} from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import {
  getMappedUpdate,
  mappedUpdate,
  postMappedApi,
  postUpdateMappedApi,
} from '../../../../services/configService'
import { Send } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../../../context/Snackbar'
import { CircularProgress } from '@mui/material'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#D0F0C0',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

export default function Updatemappinglisting({ state }) {
  const navigate = useNavigate()
  const [mappedData, setMappedData] = React.useState([])
  const [noMappedData, setNoMappedData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [row, setRow] = React.useState([])
  const [isChecked, setIsChecked] = React.useState([])
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [finaldata, setFinalData] = React.useState([])
  const [createData, setCreateData] = React.useState([])
  const [filteredItems, setFilteredItems] = React.useState([])
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const CustomFooter = () => {
    const amounts =
      Object.values(isChecked).map((item) => {
        return item.amount
      }) || []

    const tdsamounts =
      Object.values(row).map((item) => {
        return item.tds_amt
      }) || []

    const totalTdsAmount = tdsamounts.reduce((sum, amount) => sum + amount, 0)

    const deductionamounts =
      Object.values(row).map((item) => {
        return item.ded_amt
      }) || []

    const totalMappeddedAmount = deductionamounts.reduce(
      (sum, amount) => sum + amount,
      0
    )

    let totalSum = 0
    for (let i = 0; i < amounts.length; i++) {
      totalSum += amounts[i]
    }

    const tdsmappedamounts =
      Object.values(isChecked).map((item) => {
        return item.tds_amt
      }) || []

    let tdstotalSum = 0
    for (let i = 0; i < tdsmappedamounts.length; i++) {
      tdstotalSum += tdsmappedamounts[i]
    }

    const deductmappedamounts =
      Object.values(isChecked).map((item) => {
        return item.ded_amt
      }) || []

    let dedtotalSum = 0
    for (let i = 0; i < deductmappedamounts.length; i++) {
      dedtotalSum += deductmappedamounts[i]
    }

    React.useEffect(() => {
      if (state?.amount - totalSum < 0) {
        setSeverity('error')
        setMes('Amount Exceeds Balance Cheque Amount')
        setOn(true)
      }
    }, [row])

    return (
      <Grid
        container
        gap={2}
        // paddingLeft={2}
        alignItems={'end'}
        // justifyContent={'space-between'}
        borderTop={'1px solid #e5e5e5'}
      >
        <Grid item md={3}></Grid>
        <Grid item md={4}>
          <Stack direction={'column'} spacing={2} margin={1.5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                TDS AMT.
                <br />
                (Collection) :
              </p>
              <TextField
                size='small'
                label='TDS Amt.(Collection)'
                className='form_style'
                style={{ width: '50%' }}
                value={totalTdsAmount}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                DEDUCTION AMT.
                <br />
                (Collection) :
              </p>
              <TextField
                size='small'
                label='Deduct Amt.(Collection)'
                className='form_style'
                style={{ width: '50%' }}
                value={totalMappeddedAmount}
              />
            </div>
          </Stack>
        </Grid>
        <Grid item md={4}>
          {/* <Stack direction={'column'} spacing={2} margin={1.5}>
            <p>Total Amount</p>
          </Stack> */}

          <Stack direction={'column'} spacing={2} margin={1.5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>CHEQUE NO. :</p>
              <TextField
                size='small'
                className='form_style'
                label='Cheque No.'
                fullWidth
                value={state?.cheque_no}
                style={{ width: '50%' }} // Adding margin to give some space between the label and input
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>CHEQUE AMOUNT :</p>
              <TextField
                size='small'
                className='form_style'
                label='Total Cheque Amount'
                style={{ width: '50%' }}
                value={state?.amount}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>MAPPED AMOUNT :</p>

              <TextField
                size='small'
                className='form_style'
                label='Mapped Amount'
                style={{ width: '50%' }}
                value={totalSum}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>CHQ BAL AMT :</p>

              <TextField
                size='small'
                className='form_style'
                label='Cheque Balance Amount'
                style={{ width: '50%' }}
                value={state?.amount - totalSum}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                TDS AMT.
                <br />
                (Mapped) :
              </p>

              <TextField
                size='small'
                label='TDS Amt. (Mapped)'
                className='form_style'
                style={{ width: '50%' }}
                value={tdstotalSum}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                DEDUCTION AMT.
                <br />
                (Mapped) :
              </p>
              <TextField
                size='small'
                label='Deduction Amt.(Mapped)'
                className='form_style'
                style={{ width: '50%' }}
                value={dedtotalSum}
              />
            </div>

            {loading ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={25} style={{ marginRight: 10 }} />
                <p style={{ fontWeight: '550' }}>Please wait...</p>
              </div>
            ) : (
              <Button
                className='form_style'
                variant='outlined'
                // startIcon={<Send />}

                disabled={
                  isChecked.length === 0 ||
                  openSnackbar ||
                  state?.amount - totalSum < 0
                    ? true
                    : false
                }
                onClick={submitMappedRow}
              >
                Submit
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    )
  }

  async function getData(cid, rid, pl) {
    try {
      const res = await getMappedUpdate(cid, rid, pl)
      if (res.status !== 204) {
        setMappedData(res.data[0] ?? [])
        setRow([...res.data[0], ...res.data[1]])
        setNoMappedData(res.data[1] ?? [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log('wwww', finaldata)

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setIsChecked((prev) => {
        row.ded_amt = row.ded_amt ? row.ded_amt : 0
        row.tds_amt = row.tds_amt ? row.tds_amt : 0
        row.mapping_trans_id =
          row.is_mapped == 1 ? row.mapping_trans_id : 'null'
        row.is_checked = row.is_mapped == 1 ? 1 : 'null'
        row.amount = row.entries_mapped_amt
        row.receipt_id = state?.id
        row.payment_type = row?.amount_type
        return [...prev, row]
      })
      const w = createData.filter((item) => item.p_id !== row.p_id)
      setCreateData(w)
    } else {
      const x = isChecked.filter((item) => item.p_id !== row.p_id)
      const y = isChecked
        .filter((item) => item.p_id === row.p_id && row.is_mapped)
        .map((item) => ({ ...item, is_checked: 0 }))

      setIsChecked(x)
      setCreateData((prev) => [...prev, ...y])
      SetEditable(false)
    }
  }
  console.log('Checked Rows:', isChecked)

  const handleEditMappedamount = (params) => {
    setIsChecked((prev) =>
      prev.map((item) => {
        if (item.p_id == params.row.p_id) item.amount = params.value
        return item
      })
    )
  }

  const handleTdsEditMappedamount = (params) => {
    setIsChecked((prev) =>
      prev.map((item) => {
        if (item.p_id == params.row.p_id) item.tds_amt = params.value
        return item
      })
    )
  }

  const handledeductionEditMappedamount = (params) => {
    setIsChecked((prev) =>
      prev.map((item) => {
        if (item.p_id == params.row.p_id) item.ded_amt = params.value
        return item
      })
    )
  }

  async function submitMappedRow() {
    setLoading(true)
    const sendData = {
      customer_id: state.customer_id,
      id: state.id,
      query_id: state.id,
      // query_details: [...isChecked].filter(
      //   (item) => !(item.is_checked === 1 && item.balance_amount === 0)
      // ),
      is_prelogin: state.is_prelogin,
      query_details: isChecked,
    }
    const sendUnMappedData = {
      customer_id: state.customer_id,
      id: state.id,
      query_id: state.id,
      // query_details: [...createData].filter(
      //   (item) => !(item.is_checked === 1 && item.balance_amount === 0)
      // ),
      is_prelogin: state.is_prelogin,
      query_details: createData,
    }
    try {
      const res = await mappedUpdate(sendData, setError)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Updated Successfully')
        setOn(true)
        setLoading(false)
        getData(state.customer_id, state.id, state.is_prelogin === 2 ? 1 : 0)
      }
      if (createData.length > 0) {
        const unmappedres = await postUpdateMappedApi(
          sendUnMappedData,
          setError
        )
        if (unmappedres !== 204 && unmappedres !== 406) {
          setTimeout(() => {
            setSeverity('success')
            setMes('updated Successfully')
            setOn(true)
            setLoading(false)
            getData(
              state.customer_id,
              state.id,
              state.is_prelogin === 2 ? 1 : 0
            )
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const query_details = [...createData, ...isChecked].filter(
    (item) => !(item.is_checked === 1 && item.balance_amount === 0)
  )

  console.log('Filtered query_details:', createData, isChecked)

  React.useEffect(() => {
    if (error?.exceed_amount === 'Amount is greater than total amount') {
      setSeverity('error')
      setMes('Entered Amount is greater than total amount')
      setOn(true)
      setLoading(false)
    }
  }, [error])

  React.useEffect(() => {
    if (state) {
      getData(state.customer_id, state.id, state.is_prelogin === 2 ? 1 : 0)
    }
  }, [state])

  React.useEffect(() => {
    const hasZeroAmount = Object.values(isChecked).some(
      (item) => item.amount === 0
    )
    console.log('hasZeroAmount', hasZeroAmount)
    setOpenSnackbar(hasZeroAmount)
    if (hasZeroAmount) {
      setSeverity('error')
      setMes('Mapped Amount cannot be zero')
      setOn(true)
    }
  }, [isChecked])
  React.useEffect(() => {
    const x = row
      ?.filter((item) => item.is_mapped == 1)
      ?.map((item) => ({
        ...item,
        amount: item.entries_mapped_amt - item.tds_amt - item.ded_amt,
        query_id: item.pre_booking_id,
        is_checked: 1,
      }))
    // const filterdata = x.filter(
    //   (item) =>
    //     !(
    //       item.is_checked === 1 &&
    //       item.amount + item.ded_amt + item.tds_amt === item.total_amount
    //     )
    // )

    setIsChecked(x)
    setFinalData(x)
  }, [row])

  // React.useEffect(() => {
  //   const query_details = isChecked
  //   setFilteredItems(query_details);
  // }, [isChecked]);
  console.log('erroroeroe', error)

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Checkbox
            checked={isChecked.some((item) => item.p_id === params.row.p_id)}
            onChange={(event) => handleCheckboxChange(event, params.row)}
            disabled={params.row.is_prev_paid === 0}
          />
        )
      },
    },
    {
      field: 'query_code',
      headerName: 'Query Code',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.due_date
          ? dayjs(params.row.due_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    {
      field: 'amount_type',
      headerName: 'Amount Type',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return `${
          params.row.amount_type === 1
            ? 'Token Amount'
            : params.row.amount_type === 2
            ? 'Advance Amount'
            : params.row.amount_type === 3
            ? 'Final Amount'
            : params.row.amount_type === 4
            ? 'Additional Service'
            : 'N.A'
        }`
      },
    },

    {
      field: 'trans_id',
      headerName: 'Transaction Id',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'total_amount',
      headerName: 'Total Amount',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'total_mapped_amt',
      headerName: 'Mapped Total',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'entries_mapped_amt',
      headerName: 'Mapped In This Entry',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'balance_amount',
      headerName: 'Pending Amount',
      flex: 1,
      minWidth: 150,

      renderCell: (params) => <p>{params.row.balance_amount}</p>,
    },

    {
      field: 'mapped_amount',
      headerName: 'Amt to Mapped',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked?.find((item) => item.p_id == params.row.p_id) ||
          (params.row.is_mapped == 1 && params.row.approve_status != 1) ? (
          <TextField
            type='number'
            // disabled={params.row.amount_type === 4 ? true : false}
            variant='outlined'
            value={
              isChecked?.find((item) => item.p_id == params.row.p_id)?.amount
            }
            size='small'
            onChange={(event) => {
              const newValue = Number(event.target.value)
              handleEditMappedamount({ row: params.row, value: newValue })
              console.log(`Row ID: ${params.row.p_id}, New Value: ${newValue}`)
            }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : params?.row?.entries_mapped_amt}
          </p>
        )
      },
    },

    // {
    //   field: 'mapped_amount',
    //   headerName: 'Amt to Mapped',
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     return (
    //       <TextField
    //         type="number"
    //         variant="outlined"
    //         value={
    //           isChecked?.find((item) => item.p_id === params.row.p_id)?.amount || ''
    //         }
    //         size="small"
    //         onChange={(event) => {
    //           const newValue = Number(event.target.value);
    //           handleEditMappedamount({ row: params.row, value: newValue });
    //           console.log(`Row ID: ${params.row.p_id}, New Value: ${newValue}`);
    //         }}
    //       />
    //     );
    //   },
    // },

    {
      field: 'tds',
      headerName: 'TDS',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked?.find((item) => item.p_id == params.row.p_id) ||
          (params.row.is_mapped == 1 && params.row.approve_status != 1) ? (
          <TextField
            type='number'
            variant='outlined'
            value={
              isChecked.find((item) => item.p_id == params.row.p_id)?.tds_amt
            }
            size='small'
            onChange={(event) => {
              const newValue = Number(event.target.value)
              handleTdsEditMappedamount({ row: params.row, value: newValue })
              console.log(`Row ID: ${params.row.p_id}, New Value: ${newValue}`)
            }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : params?.row?.paid_tds_amt
              ? params?.row?.paid_tds_amt
              : params?.row?.tds_amt}
          </p>
        )
      },
    },

    {
      field: 'deduction',
      headerName: 'Deduct',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked?.find((item) => item.p_id == params.row.p_id) ||
          (params.row.is_mapped == 1 && params.row.approve_status != 1) ? (
          <TextField
            type='number'
            variant='outlined'
            value={
              isChecked.find((item) => item.p_id == params.row.p_id)?.ded_amt
            }
            size='small'
            onChange={(event) => {
              const newValue = Number(event.target.value)
              handledeductionEditMappedamount({
                row: params.row,
                value: newValue,
              })
              console.log(`Row ID: ${params.row.p_id}, New Value: ${newValue}`)
            }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : params?.row?.ded_amt
              ? params?.row?.ded_amt
              : 0}
          </p>
        )
      },
    },
    {
      field: 'balanceamount',
      headerName: 'Balance Amt',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked?.find(
          (item) =>
            item.p_id == params.row.p_id && params.row.approve_status != 1
        ) ||
          (params.row.is_mapped == 1 && params.row.approve_status != 1) ? (
          <TextField
            type='number'
            variant='outlined'
            disabled
            value={
              isChecked.find((item) => item.p_id == params.row.p_id)
                ?.total_amount -
              (isChecked.find((item) => item.p_id == params.row.p_id)?.amount +
                isChecked.find((item) => item.p_id == params.row.p_id)
                  ?.tds_amt +
                isChecked.find((item) => item.p_id == params.row.p_id)?.ded_amt)
            }
            size='small'
            // onChange={(event) => {
            //   const newValue = Number(event.target.value)
            //   handleTdsEditMappedamount({ row: params.row, value: newValue })
            //   console.log(`Row ID: ${params.row.p_id}, New Value: ${newValue}`)
            // }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : 0}
          </p>
        )
      },
    },
  ]
  React.useEffect(() => {
    console.log('useEffect is running', error)
    if (error.unpaid) {
      setTimeout(() => {
        setSeverity('error')
        setMes(
          'Select amount type in order: final, advance, token for un-mapping entries'
        )
        setOn(true)
      }, 1000)
    }
  }, [error])

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <StripedDataGrid
        rows={row?.map((item, index) => ({
          ...item,
          id: index + 1,
        }))}
        getRowId={(row) => row.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[100, 250, 500]}
        initialState={{
          pagination: { paginationModel: { pageSize: 100, page: 0 } },
        }}
        disableRowSelectionOnClick
      />
      <Card>
        <CardContent>
          <CustomFooter />
        </CardContent>
      </Card>
    </Box>
  )
}
