import * as React from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import { alpha, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal'
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone'
import {
  allUnmappedApi,
  getAgent,
  getReceiptCollection,
  receiptDelete,
  receiptVerifyApi,
} from '../../../../services/configService'
import TableMenu from '../../../../components/table_modal/TableMenu'
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone'
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone'
import { useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../../../context/Snackbar'
import {
  AutoMode,
  Check,
  CheckCircle,
  CheckCircleTwoTone,
  Download,
  HighlightOffTwoTone,
  Info,
  Update,
  VerifiedTwoTone,
} from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import Deletecard from '../../../../components/card/Deletecard'
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Badge,
  Divider,
} from '@mui/material'
import { downloadAttachment } from '../../../../utils/common'
import useAuth from '../../../../hooks/useAuth'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#D0F0C0',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const paymentMode = {
  1: 'Cheque',
  2: 'NEFT/RTGS/IMPS',
  3: 'Cash',
  4: 'Online',
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  bgcolor: 'background.paper',
  borderTop: '6px solid lightgrey',
  boxShadow: 24,
  borderRadius: '1px 1px 8px 8px',
}

export default function ReceiptsListingTable() {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [receiptData, setReceiptData] = React.useState([])
  const [agent, setAgent] = React.useState([])
  const [deleteRow, setDeleteRow] = React.useState(false)
  const [unmappedRow, setUnmappedRow] = React.useState(false)
  const [selectedDltId, setSelectedDltId] = React.useState(null)
  const [selectedUnMappedId, setSelectedUnMappedId] = React.useState(null)
  const [selectedRowIsPrelogin, setSelectedRowIsPrelogin] = React.useState(null)
  const [countdown, setCountdown] = React.useState(3) // Starting countdown value
  const [isYesDisabled, setIsYesDisabled] = React.useState(true)
  const [isVerifyModalOpen, setIsVerifyModalOpen] = React.useState(false)
  const [rowToVerify, setRowToVerify] = React.useState(null)
  async function getReceiptlist() {
    try {
      const res = await getReceiptCollection()
      if (res !== 204 && res !== 401) {
        setReceiptData(res.data)
        console.log('@!@!!@', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getAgentlist() {
    const get_agentlist = await getAgent()
    setAgent(get_agentlist?.data?.filter((item) => item.is_active === 1))
    console.log('get_agentlist', get_agentlist)
  }

  const customerID = receiptData?.map((item) => item?.customer_id)

  const customerName = agent?.map((item) => {
    return {
      id: item.id,
      name: item.name,
    }
  })
  const idToNameMap = customerName?.reduce((acc, customer) => {
    acc[customer.id] = customer.name
    return acc
  }, {})

  // Use the customerId list to get the corresponding names
  // const customerNames = customerID?.map((id) => idToNameMap[id])

  // console.log('customerName', customerNames)

  const handleMapped = (row) => {
    const data = row
    navigate('/mapping', { state: data })
  }

  const openVerifyModal = (row) => {
    setRowToVerify(row.id)
    setIsVerifyModalOpen(true)
  }
  const handleVerified = async () => {
    try {
      const res = await receiptVerifyApi(rowToVerify)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Receipt verfication updated Successfully')
        setOn(true)
        getReceiptlist()
        setIsVerifyModalOpen(false)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }

  const handleUpdateMapped = (row) => {
    const rowdata = row
    navigate('/update-mapping', { state: rowdata })
  }

  const handleEditRow = (row) => {
    const data = row
    navigate('/create-receipt', { state: data })
  }
  const handleDeleteModal = (row) => {
    setSelectedDltId(row.id)
    setDeleteRow(true)
  }
  const handleUnmapeedModal = (row) => {
    setSelectedUnMappedId(row.id)
    setSelectedRowIsPrelogin(row.is_prelogin)
    setUnmappedRow(true)
  }
  const handledeleteModalClose = () => setDeleteRow(false)
  const handleunmappedModalClose = () => setUnmappedRow(false)

  const handleYesClick = async () => {
    const sendData = {
      receipt_id: selectedUnMappedId,
      is_prelogin: selectedRowIsPrelogin,
    }
    try {
      const res = await allUnmappedApi(sendData)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('All Queries Unmapped Successfully')
        setOn(true)
        getReceiptlist()
        setUnmappedRow(false)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }
  const handleDeleteRow = async (row) => {
    try {
      const res = await receiptDelete(selectedDltId)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Receipt Deleted Successfully')
        setOn(true)
        getReceiptlist()
        handledeleteModalClose()
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }

  const columns = [
    { field: 'sno', headerName: 'ID', width: 90 },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      minWidth: 150,
      flex: 1,
    },
    { field: 'amount', headerName: 'Amount Paid', minWidth: 150, flex: 1 },
    {
      field: 'payment_modee',
      headerName: 'Payment Mode',
      renderCell: (params) => {
        const color =
          params.row?.payment_mode === 1
            ? 'info'
            : params.row?.payment_mode === 2
            ? 'success'
            : params.row?.payment_mode === 3
            ? 'warning'
            : params.row?.payment_mode === 4
            ? 'secondary'
            : 'error'
        return (
          <Chip
            label={params.row?.payment_modee || 'Unknown'}
            color={color}
            variant='outlined'
          />
        )
      },
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'cheque_no',
      headerName: 'Cheque/UTR No.',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params.row.cheque_no == 'undefined'
          ? 'N.A'
          : params.row.cheque_no
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      renderCell: (params) => (
        <Chip
          variant='outlined'
          color={params.row.is_verified === 1 ? 'success' : 'error'}
          label={
            <>
              {params.row.is_verified === 1 ? (
                <div style={{ display: 'flex', gap: '5px', fontWeight: 500 }}>
                  <VerifiedTwoTone color='success' fontSize='small' />
                  Verified
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '5px', fontWeight: 500 }}>
                  Not Verified
                </div>
              )}
            </>
          }
        />
      ),
    },
    {
      field: 'cheque_date',
      headerName: 'Cheque Date',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params.row.payment_mode == 4
          ? dayjs(params.row.added_date).format('DD-MM-YYYY')
          : dayjs(params.row.cheque_date).format('DD-MM-YYYY')
      },
    },
    // { field: 'check_date', headerName: 'TDS Amount', minWidth: 150, flex: 1 },
    { field: 'remarks', headerName: 'Remarks', minWidth: 150, flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            {row.is_prelogin !== 1 ? (
              <TableMenu
                data={[
                  {
                    id: 1,
                    title:
                      row?.balance_amount == row?.amount
                        ? 'Mapping To'
                        : 'Update Mapped',
                    icon:
                      row?.balance_amount == row?.amount ? (
                        <ArrowCircleRightTwoToneIcon
                          fontSize='small'
                          color='success'
                        />
                      ) : (
                        <Update fontSize='small' color='secondary' />
                      ),
                    disable:
                      row?.balance_amount == row?.amount &&
                      row.is_verified === 0
                        ? true
                        : false,
                     
                    fn: () =>
                      row?.balance_amount == row?.amount
                        ? handleMapped(row)
                        : handleUpdateMapped(row),
                  },
                  {
                    id: 2,
                    title: 'Update Receipt',
                    icon: <EditNoteTwoToneIcon fontSize='small' color='info' />,
                    disable: row?.balance_amount !== row?.amount ? true : false,
                    fn: () => handleEditRow(row),
                  },
                  {
                    id: 3,
                    title: 'Delete Receipt',
                    icon: (
                      <DeleteSweepTwoToneIcon fontSize='small' color='error' />
                    ),
                    disable: row?.balance_amount !== row?.amount ? true : false,
                    fn: () => handleDeleteModal(row),
                  },
                  {
                    id: 4,
                    title: 'UnMapped All',
                    icon: <AutoMode fontSize='small' color='warning' />,
                    disable:
                      row?.balance_amount == row?.amount ||
                      row?.payment_mode === 4
                        ? true
                        : false,
                    fn: () => handleUnmapeedModal(row),
                  },
                  {
                    id: 5,
                    title: 'Download Attachment',
                    icon: <Download fontSize='small' color='info' />,
                    disable: row?.attachment ? false : true,
                    fn: () => {
                      downloadAttachment(row.attachment, 'Attachment File')
                    },
                  },

                  auth.role === 4 &&
                    row?.balance_amount == row?.amount && {
                      id: 5,
                      title:
                        row?.is_verified === 1
                          ? 'Set as Not Verified'
                          : 'Confirm Verification',
                      icon:
                        row?.is_verified === 1 ? (
                          <HighlightOffTwoTone fontSize='small' color='error' />
                        ) : (
                          <CheckCircleTwoTone
                            fontSize='small'
                            color='success'
                          />
                        ),
                      // disable: row?.attachment ? false : true,
                      fn: () => {
                        openVerifyModal(row)
                      },
                    },
                ]}
              />
            ) : (
              <Tooltip title='Pre-Login Booking'>
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            )}
          </>,
        ]
      },
    },
  ]

  React.useEffect(() => {
    getReceiptlist()
    getAgentlist()
  }, [])

  React.useEffect(() => {
    if (unmappedRow) {
      setCountdown(3)
      setIsYesDisabled(true)
    }
  }, [unmappedRow])

  React.useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(timer) // Cleanup interval on component unmount
    } else {
      setIsYesDisabled(false)
    }
  }, [countdown])

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <StripedDataGrid
        rows={receiptData?.map((item, index) => ({
          ...item,

          sno: index + 1,
          payment_modee: paymentMode[item?.payment_mode],
        }))}
        getRowClassName={(params) =>
          params.row?.balance_amount !== params.row?.amount
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[10, 25, 50]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        disableRowSelectionOnClick
      />
      <Modal
        open={deleteRow}
        onClose={handleDeleteModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Deletecard
          btnText={'Delete'}
          onClickBack={handledeleteModalClose}
          onClickSubmit={handleDeleteRow}
          subheading={'Delete Receipt'}
        />
      </Modal>
      <Modal open={unmappedRow} onClose={handleunmappedModalClose}>
        <Card sx={style}>
          <CardContent>
            <Typography sx={{ fontSize: '22px', fontWeight: 600 }}>
              Confirm Action
            </Typography>
            <p style={{ marginTop: 20, fontSize: '16px', fontWeight: 500 }}>
              Are you sure you want to unmap all queries associated with this
              receipt?
            </p>
            <div style={{ marginTop: 20, marginBottom: 20, float: 'right' }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleYesClick}
                disabled={isYesDisabled}
                sx={{
                  background: 'black',
                  '&:hover': {
                    color: 'white',
                    backgroundColor: '#61699f',
                    // transition: 'backgroundColor 0.3s ease',
                  },
                }}
              >
                {isYesDisabled ? `Yes (${countdown})` : 'Yes'}
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleunmappedModalClose}
                sx={{
                  marginLeft: 2,
                  background: 'lightgrey',
                  color: 'black',
                  '&:hover': {
                    color: 'white',
                    backgroundColor: 'grey',
                    transition: 'backgroundColor 0.3s ease',
                  },
                }}
              >
                No
              </Button>
            </div>
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={isVerifyModalOpen}
        onClose={() => setIsVerifyModalOpen(false)}
        aria-labelledby='verify-modal-title'
        aria-describedby='verify-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography id='verify-modal-title' variant='h5' fontWeight='bold'>
            Verification Update
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography
            id='verify-modal-description'
            variant='body1'
            sx={{ color: 'text.secondary', fontWeight: 500 }}
          >
            Are you sure you want to update the verification on this receipt?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button
              variant='outlined'
              onClick={() => setIsVerifyModalOpen(false)}
              sx={{
                color: 'grey.700',
                borderColor: 'grey.400',
                '&:hover': {
                  borderColor: 'grey.600',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => handleVerified()}
              sx={{
                ml: 2,
                bgcolor: 'black',
                '&:hover': {
                  bgcolor: '#61699f',
                },
              }}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
